import useAuth from "@/modules/auth/composables/useAuth";
import {Ref, ref} from "vue";
import {v4 as uuid} from "uuid";
import {LocationForm} from "@/modules/locations/interfaces";
import store from "@/store";

const {companyId} = useAuth()
const defaultValues = (): LocationForm => {
    return {
        id: uuid(),
        code: "",
        name: "",
        mainContact: "",
        barcode: "",
        address: "",
        itemStatus: "available",
        status: "active",
        companyId: companyId.value
    }
}
const locationForm: Ref<LocationForm> = ref(defaultValues())

const useLocation = () => {
    const catalogs = {
        status: [
            {id: 'active', title: 'Activo'},
            {id: 'inactive', title: 'Inactivo'},
        ],
        itemStatus: [
            {id: 'available', title: 'Disponible'},
            {id: 'not_available', title: 'No disponible'},
        ]
    };
    const clearForm = () => locationForm.value = defaultValues()

    const createLocation = async (locationForm: LocationForm) => {
        return await store.dispatch('locations/createLocation', locationForm)
    }

    const updateLocation = async (locationForm: LocationForm) => {
        return await store.dispatch('locations/updateLocation', locationForm)
    }

    const getLocation = async (id: string): Promise<LocationForm> => {
        const {ok, data} = await store.dispatch('locations/getLocation', id)

        return data;
    }

    return {
        locationForm,
        catalogs,
        clearForm,
        createLocation,
        updateLocation,
        getLocation
    }
}

export default useLocation;
