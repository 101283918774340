
import {onMounted, ref, watch} from "vue";
import useLocation from "@/modules/locations/composables/useLocation";
import usePanel from "@/composables/usePanel";

const {locationForm} = useLocation();

export default {
    props: ['catalogs'],
    setup() {
        const {showPanel, togglePanel} = usePanel()

        const code = ref('')
        const name = ref('')
        const mainContact = ref('')
        const barcode = ref('')
        const address = ref('')
        const itemStatus = ref('')
        const status = ref('')

        onMounted(() => {
            code.value = locationForm.value.code
            name.value = locationForm.value.name
            mainContact.value = locationForm.value.mainContact
            barcode.value = locationForm.value.barcode
            address.value = locationForm.value.address
            itemStatus.value = locationForm.value.itemStatus
            status.value = locationForm.value.status
        })

        watch(code, val => locationForm.value.code = val)
        watch(name, val => locationForm.value.name = val)
        watch(mainContact, val => locationForm.value.mainContact = val)
        watch(barcode, val => locationForm.value.barcode = val)
        watch(address, val => locationForm.value.address = val)
        watch(itemStatus, val => locationForm.value.itemStatus = val)
        watch(status, val => locationForm.value.status = val)

        return {
            showPanel,
            togglePanel,
            code,
            name,
            mainContact,
            barcode,
            address,
            itemStatus,
            status,
            locationForm
        }
    }
}
